import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Menu, Icon, Dropdown } from 'semantic-ui-react'
import '../styles/navigation.css'

const { bool, func, shape, instanceOf } = PropTypes

const Navigation = props => (
    <Menu className="main-navigation">
        <Menu.Item>
            <NavLink to="/">
                View all networks
            </NavLink>
        </Menu.Item>
        <Menu.Item>
            <Dropdown
                onChange={props.changeNetworkRoute}
                className="network-dropdown"
                placeholder="Select Network"
                selectOnNavigation={false}
                fluid
                search
                selection
                options={props.dropdownOptions}
            />
        </Menu.Item>
        <Menu.Menu position="right">
            <Menu.Item className="user-menu">
                <p className="user-name">{props.user.name}</p>
                <span className="user-sign-out" onClick={props.unAuthUser}>
                    Sign Out
                    <Icon name="log out" className="sign-out-icon" />
                </span>
            </Menu.Item>
        </Menu.Menu>
    </Menu>
)

Navigation.defaultProps = {
    user: {},
    dropdownOptions: []
}

Navigation.propTypes = {
    changeNetworkRoute: func.isRequired,
    unAuthUser: func.isRequired,
    user: shape({
        isAuthed: bool.isRequired
    }),
    dropdownOptions: instanceOf(Array)
}

export default Navigation
