import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import asyncComponent from '../components/async'
import NavigationContainer from './navigation-container'

const AsyncLoginContainer = asyncComponent(() => import('./login-container'))
const AsyncAllNetworksContainer = asyncComponent(() => import('./all-networks-container'))
const AsyncNetworkContainer = asyncComponent(() => import('./network-container'))
const Async404 = asyncComponent(() => import('../components/error-404'))

const { bool, shape } = PropTypes

class MainContainer extends Component {
    static propTypes = {
        user: shape({
            isAuthed: bool.isRequired
        }).isRequired
    }

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="main-container">
                {this.props.user.isAuthed ?
                    <Router>
                        <Fragment>
                            <NavigationContainer />
                            <Switch>
                                <Route exact path="/" component={AsyncAllNetworksContainer} />
                                <Route path="/network/:networkID" component={AsyncNetworkContainer} />
                                <Route component={Async404} />
                            </Switch>
                        </Fragment>
                    </Router>
                    :
                    <AsyncLoginContainer />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(MainContainer)
