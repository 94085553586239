import { combineReducers } from 'redux'
import user from './user'
import networks from './networks'

const rootReducer = combineReducers({
    user,
    networks
})

export default rootReducer
