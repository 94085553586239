import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Navigation from '../components/navigation'
import { unAuthUser } from '../actions/user'

const { shape, func, instanceOf } = PropTypes

class NavigationContainer extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        history: instanceOf(Object).isRequired,
        user: shape({}).isRequired,
        networks: shape({}).isRequired
    }

    constructor(props) {
        super(props)
        this.unAuthUser = this.unAuthUser.bind(this)
        this.changeNetworkRoute = this.changeNetworkRoute.bind(this)
    }

    unAuthUser() {
        this.props.dispatch(unAuthUser())
    }

    changeNetworkRoute(e, data) {
        this.props.history.replace(`/network/${data.value}`)
    }

    formatNetworksToOptions() {
        const { networks } = this.props
        const networkKeys = Object.keys(networks)
        const dropdownOptions = []
        networkKeys.map(networkID => (
            dropdownOptions.push({
                key: networkID,
                value: networkID,
                text: networks[networkID].name
            })
        ))
        return dropdownOptions
    }

    render() {
        return (
            <Navigation
                user={this.props.user}
                dropdownOptions={this.formatNetworksToOptions()}
                changeNetworkRoute={this.changeNetworkRoute}
                unAuthUser={this.unAuthUser}
            />
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    networks: state.networks
})

export default withRouter(
    connect(mapStateToProps)(NavigationContainer)
)
